import axios, {
  AxiosInstance,
  CancelTokenStatic,
  InternalAxiosRequestConfig,
} from 'axios';
import LocalStorage from '../LocalStorage';

interface CustomAxiosInstance extends AxiosInstance {
  CancelToken?: CancelTokenStatic;
  isCancel?(value: any): boolean;
}

const AxiosClient: CustomAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL!!,
});

AxiosClient.CancelToken = axios.CancelToken;
AxiosClient.isCancel = axios.isCancel;

AxiosClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  // One sample custom header referring to authorization secret provided
  const accessToken = LocalStorage.get.accessToken();
  if (accessToken) {
    config.headers!!.Authorization = `Bearer ${LocalStorage.get.accessToken()}`;
  }

  return config;
});


export default AxiosClient;
