import { CompetitionCardData, ContestDetailsForResultNavigator } from "@wizkidscarnival/wizkids-carnival-commons/models";

export enum ColorType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Accent = 'Accent',
  HEADING_FONT_COLOR = 'heading_font_color',
  BODY_FONT_COLOR = 'body_font_color',
}

export enum LogoType {
  Small = 's',
  Medium = 'm',
  Large = 'l',
  ExtraLarge = 'xl'
}

export enum HeaderLogoSize {
  NORMAL = 'normal',
  LARGE = 'large'
}

export enum DashboardHostingPlatform {
  PARTNER = 'partner',  // Opens the brand dashboard app for submission
  WIZ_KIDS_APPS = 'wizkids', // Opens the wizkids carnival app for submission
  NONE = 'none' // No dashboard is available for submission, only offline submission allowed
}

export enum RegistrationChannel {
  SELF = 'self',
  EXTERNAL = 'external'
}

export interface AppLogo {
  url: string;
  width: number;
  height: number;
}

export interface AppConfig {
  name: string;
  partnerType: string;
  domain: string;
  companyId: string;
  companyWebsite: string;
  address: string;
  pinCode: string;
  city: string;
  logo: {
    smallLogo: AppLogo;
    largeLogo: AppLogo;
  };
  color: {
    primary: string;
    secondary: string;
    accent: string;
    headingFontColor: string;
    bodyFontColor: string;
  };
  supportDetails: {
    email: string;
    phone: string;
  };
  isBrandContestActive: boolean;
  closureMessage: string;
  appStatus: AppStatus,
  redirectingTo: string;
  leagueName: string;
  dashboardHostedOn: DashboardHostingPlatform;
  registrationChannel: RegistrationChannel;
  externalRegistrationDetails?: {
    url: string;
    buttonText?: string;
  },
  headerLogoSize: HeaderLogoSize;
  isOneContestBrand: boolean;
  checkContestEnrollmentOnLogin: boolean;
  showBrandTermsAndConditionsModal: boolean;
  showUploadSubmissionPopup: boolean,
  continueJourneyRedirectionLink: string,
  continueJourneyButtonText: string,
  submissionUploadStartDate: string,
  isRegistrationClosed: boolean,
  registrationClosedMessageHTML: string,
  isResultsAvailable: boolean,
  resultPageUrl: string,
}

export interface ContestQualificationLevel {
  id: string;
  levelName: string;
  levelNumber: number;
  minimumQualificationLevel: number;
}

export interface ChildData {
  id: string;
  childId: string;
  fullName: string;
  dateOfBirth: string;
  profileImage: string;
  age: number;
  section: string;
  grade: string;
  schoolName: string;
  fieldOfInterest: string[];
  teacherName: string;
  teacherMobileNumber: string;
  contestQualificationLevel: ContestQualificationLevel;
}

export interface SubscriptionData {
  isSubscribed: boolean;
  isPremiumUser: boolean;
  name: string;
  detailedBenefits: string;
  perks: string[];
  subscriptionStartDate: string;
  subscriptionExpiryDate: string;
}

export interface UserData {
  userId: string;
  name: string;
  phoneNumber: string;
  email: string;
  profilePictureUrl: string;
  children: ChildData[];
  subscription: SubscriptionData;
  address: string;
  pinCode: string;
  city: string;
  alternatePhoneNumber: string;
  source: string;
  agentName: string;
}


export interface SidebarState {
  isOpen: boolean;
}

export interface DropdownData {
  value: string;
  isPublic: boolean;
  type: string;
  urlText: string;
}

export interface CalenderDate {
  date: string;
  month: string;
  year: string;
}


export interface ThemeItem {
  imgSrc: string;
  title: string;
  description: string;
}

export interface Theme {
  title: string;
  description: string;
  data: ThemeItem[];
}

export interface Prize {
  first: string,
  second: string,
  third: string,
}

export interface FAQ {
  id: string,
  question: string,
  answer: string
}

export interface CompetitionDetailsData {
  isEnrolled: boolean;
  contestId: string;
  title: string;
  description: string;
  thingsToKnow: string;
  minAge: number;
  maxAge: number;
  level: string;
  amount: number;
  themeGuidelinesPdfLink: string;
  theme: Theme,
  submitDate: CalenderDate;
  resultDate: CalenderDate;
  certificationAndGiftCardDate: CalenderDate;
  publicationDate: CalenderDate;
  prize: Prize;
  FAQ: FAQ[]
}

export interface DetailPageNavItem {
  divId?: string;
  imgUrl: string;
  label: string;
}

export interface ContestType {
  key: string;
  name: string;
}

export type CompetitionCardDetails = CompetitionCardData & {
  maximumUploadForAChild: number
}

export interface RedirectionPageData {
  path: string;
  callbackPath?: string;
  contestName?: string;
}

export interface ToastData {
  isShowing: boolean;
  message: string | {
    response: string;
    nextStep: string;
  };
  isSuccess: boolean;
}


export interface SubscriptionStatus {
  isComboAvailable: boolean,
  isPremiumUser: boolean,
  enrollmentLeft: number,
}

export type ValidatorResponse = | {
  isValid: true;
} | {
  isValid: false;
  error: string;
}

export interface Option {
  label: string;
  value: string;
}

export enum InputType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  FILE = 'file',
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
}

export enum AppStatus {
  IN_DEVELOPMENT = 'IN_DEVELOPMENT',
  IN_MAINTENANCE = 'IN_MAINTENANCE',
  REDIRECTING = 'REDIRECTING',
  LIVE = 'LIVE'
}

export interface ComboData {
  comboCode: string;
  comboName: string;
  discountedPrice: number;
  maximumItemCount: number;
}

export enum PartnerType {
  SCHOOL = 'school',
  BRAND = 'brand',
}

export interface ContestListOption extends Option {
  price: number;
}

export interface ContestSlotsDateAvailability {
  contestId: string;
  availableDates: string[];
}

export interface SlotTime {
  id: number | string,
  date: string,
  startTime: string,
  endTime: string,
  value: string,
}

export interface ContestTimeSlotDetail {
  contestId: string;
  date: string;
  time: string;
}

export enum UserPreferenceForBrandTermsAndConditionsModalVisibility {
  SHOW = 'show',
  HIDE = 'hide',
  NOT_SET = 'not_set'
}

export interface UserPreferencesForBrandTermsAndConditionsModal {
  [key: string]: UserPreferenceForBrandTermsAndConditionsModalVisibility
}

export interface SubmissionUploadConfig {
  formatSupportedMessage: string;
  supportedFormats: {
    type: string;
    subTypes?: string[];
    maxFileSizeInMB: number;
  }[];
  maximumAllowedUploadsPerChild: number;
}

export interface ChildEntryForResults {
  id: string;
  name: string;
  school: string;
  entryThumbnail: string;
  upvotes: number;
  entryUrl: string;
  hasUserVoted: boolean;
  city?: string;
}

export interface PrizeDetails {
  type: string;
  icon?: string;
  entries: ChildEntryForResults[];
}

export interface CategoryWiseEntries {
  ageCategory: string;
  prizeDetails: PrizeDetails[];
}

export interface ContestDetailsForResult {
  contestId: string;
  title: string;
  submissionMode: string;
  enrollmentStartDate: string | null;
  enrollmentEndDate: string | null;
}

export interface ContestWithEntries {
  contestId: string;
  title: string;
  submissionMode: string;
  enrollmentStartDate: string | null;
  enrollmentEndDate: string | null;
  entries: CategoryWiseEntries[];
}

export interface ResultPageDetails {
  title: string;
  contests: ContestWithEntries[];
  nextSteps?: string[];
}
export interface PopularChoiceAwardPageConfig {
  title: string;
  contests: ContestDetailsForResultNavigator[];
  nextSteps?: string[];
  isVotingOpen: boolean;
}
